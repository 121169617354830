<template>
  <c-box flex-grow="1">
    <c-breadcrumb
      font-size="14px"
      line-height="21px"
      separator="›"
      :d="['none', 'block']"
    >
      <c-breadcrumb-item>
        <c-breadcrumb-link
          color="primary.400"
          as="router-link"
          to="/admin/coupons"
        >
          Manajemen Kupon
        </c-breadcrumb-link>
      </c-breadcrumb-item>
      <c-breadcrumb-item>
        <c-breadcrumb-link is-current>
          Detail Kupon
        </c-breadcrumb-link>
      </c-breadcrumb-item>
    </c-breadcrumb>
    <c-divider my="16px" />
    <c-flex
      justify="space-between"
      align="center"
      mb="30px"
    >
      <c-heading
        as="h3"
        font-family="Montserrat"
        font-weight="bold"
        font-size="24px"
        line-height="36px"
      >
        Detail Kupon
      </c-heading>
      <c-box>
        <c-button
          variant="solid"
          variant-color="red"
          border-radius="60px"
          min-w="114px"
          h="auto"
          py="13px"
          px="18px"
          mr="20px"
          font-size="16px"
          font-weight="500"
          line-height="24px"
          @click="isOpenModalConfirmDeleteCoupon = true"
        >
          <c-image
            :src="require('@/assets/icon-trash.svg')"
            alt="file program"
            mr="10px"
            border-radius="6px"
          />
          Hapus
        </c-button>
        <c-button
          variant="solid"
          variant-color="primary"
          border-radius="60px"
          h="auto"
          min-w="114px"
          py="13px"
          px="18px"
          font-size="16px"
          font-weight="500"
          line-height="24px"
          as="router-link"
          :to="{
            name: 'admin.coupons.edit',
            params: { id: id },
          }"
        >
          <c-image
            :src="require('@/assets/icon-pencil.svg')"
            alt="file program"
            mr="10px"
            border-radius="6px"
          />
          Edit
        </c-button>
      </c-box>
    </c-flex>

    <c-box>
      <c-form-control mb="30px">
        <c-text
          mb="2px"
          color="primary.400"
          font-weight="500"
          font-size="16px"
          line-height="24px"
        >
          Kode
        </c-text>
        <c-text
          color="superDarkGray.900"
          font-weight="500"
          font-size="18px"
          line-height="27px"
        >
          {{ item.code }}
        </c-text>
      </c-form-control>
      <c-form-control mb="30px">
        <c-text
          mb="2px"
          color="primary.400"
          font-weight="500"
          font-size="16px"
          line-height="24px"
        >
          Nama Kupon
        </c-text>
        <c-text
          color="superDarkGray.900"
          font-size="18px"
          line-height="27px"
        >
          {{ item.name }}
        </c-text>
      </c-form-control>
      <c-form-control mb="30px">
        <c-text
          mb="2px"
          color="primary.400"
          font-weight="500"
          font-size="16px"
          line-height="24px"
        >
          Kuota
        </c-text>
        <c-text
          color="superDarkGray.900"
          font-size="18px"
          line-height="27px"
        >
          {{ item.quota }}
        </c-text>
      </c-form-control>
      <c-form-control mb="30px">
        <c-text
          mb="2px"
          color="primary.400"
          font-weight="500"
          font-size="16px"
          line-height="24px"
        >
          Start
        </c-text>
        <c-text
          color="superDarkGray.900"
          font-size="18px"
          line-height="27px"
        >
          {{ item.startAt }} - {{ item.endAt }}
        </c-text>
      </c-form-control>
      <c-form-control mb="30px">
        <c-text
          mb="2px"
          color="primary.400"
          font-weight="500"
          font-size="16px"
          line-height="24px"
        >
          Potongan
        </c-text>
        <c-text
          color="superDarkGray.900"
          font-size="18px"
          line-height="27px"
        >
          {{ getDiscount(item) }}
        </c-text>
      </c-form-control>
    </c-box>

    <BaseModal
      :is-open="isOpenModalConfirmDeleteCoupon"
      :close-on-overlay-click="false"
      :with-button-close="false"
    >
      <template #header>
        <c-box
          display="flex"
          justify-content="center"
          margin="24px 0 0 0"
        >
          <c-image
            height="120px"
            object-fit="cover"
            src="https://ik.imagekit.io/dietela/pwa_webp/popup/delete.png?updatedAt=1690890254641"
            alt="image confirmation delete coupon"
          />
        </c-box>
      </template>
      <template #body>
        <c-flex
          justify-content="center"
          padding="30px 24px"
          text-align="center"
        >
          <BaseText
            size-mobile="18px"
            size-desktop="20px"
            color="primary.400"
          >
            Anda yakin ingin hapus data kupon “{{ name }}”?
          </BaseText>
        </c-flex>
      </template>
      <template #footer>
        <c-flex
          gap="20px"
          width="100%"
          padding-left="24px"
          padding-right="24px"
          margin-bottom="24px"
        >
          <BaseButton
            :left-svg-icon="require('@/assets/icons/icon-circle-close.svg')"
            left-svg-icon-color="#008C81"
            variant="outlined"
            rounded="1000px"
            width="100%"
            :disabled="isLoadingDeleteCoupon"
            @click="isOpenModalConfirmDeleteCoupon = false"
          >
            Batal
          </BaseButton>
          <BaseButton
            :right-svg-icon="require('@/assets/icons/icon-circle-check.svg')"
            right-svg-icon-color="white"
            color="danger"
            rounded="1000px"
            width="100%"
            :is-loading="isLoadingDeleteCoupon"
            @click="onDelete"
          >
            Hapus
          </BaseButton>
        </c-flex>
      </template>
    </BaseModal>

    <BaseModal
      :is-open="isOpenModalSuccessDeleteCoupon"
      :close-on-overlay-click="false"
      :with-button-close="false"
    >
      <template #header>
        <c-box
          display="flex"
          justify-content="center"
          margin="24px 0 0 0"
        >
          <c-image
            height="160px"
            object-fit="cover"
            src="https://ik.imagekit.io/dietela/pwa_webp/popup/berhasil_data.png?updatedAt=1690890254620"
            alt="image success delete coupon"
          />
        </c-box>
      </template>
      <template #body>
        <c-flex
          justify-content="center"
          padding="30px 24px"
        >
          <BaseText
            size-mobile="18px"
            size-desktop="20px"
            color="primary.400"
          >
            Data kupon berhasil dihapus!
          </BaseText>
        </c-flex>
      </template>
      <template #footer>
        <c-box
          width="100%"
          padding-left="24px"
          padding-right="24px"
          margin-bottom="24px"
        >
          <BaseButton
            :right-svg-icon="require('@/assets/icons/icon-circle-check.svg')"
            right-svg-icon-color="white"
            color="primary"
            rounded="1000px"
            width="100%"
            @click="isOpenModalSuccessDeleteCoupon = false"
          >
            Oke
          </BaseButton>
        </c-box>
      </template>
    </BaseModal>
  </c-box>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import BaseText from '@/components/elements/base-text.vue'
import BaseButton from '@/components/elements/base-button.vue'
import BaseModal from '@/components/elements/base-modal.vue'

export default {
  name: 'ManagementCouponDetail',
  components: {
    BaseModal,
    BaseButton,
    BaseText,
  },
  data() {
    return {
      isLoadingDeleteCoupon: false,
      isOpenModalConfirmDeleteCoupon: false,
      isOpenModalSuccessDeleteCoupon: false,
    }
  },
  computed: {
    ...mapGetters({
      item: 'admCoupons/item',
    }),
    id() {
      return this.$route.params.id
    },
  },
  async mounted() {
    this.detailCoupons(this.id)
  },
  methods: {
    ...mapActions({
      detailCoupons: 'admCoupons/detailCouponsAdmin',
      deleteCoupons: 'admCoupons/deleteCouponsAdmin',
    }),
    getDiscount(item) {
      if (item.type === 'percent') {
        return `${item.totalDiscount}%`
      }
      return `Rp${Number(item.totalDiscount).toLocaleString('id')}`
    },
    closeDialog() {
      this.isOpenModalConfirmDeleteCoupon = false
    },
    onDelete() {
      this.isLoadingDeleteCoupon = true
      if (!this.isOpenModalConfirmDeleteCoupon) return
      this.deleteCoupons(this.id)
        .then(() => {
          this.$toast({
            title: 'Success',
            description: 'Kupon has been deleted.',
            status: 'success',
            duration: 5000,
            position: 'bottom-right',
            variant: 'subtle',
          })
          this.$router.push({
            name: 'admin.coupons',
          })
          this.isOpenModalSuccessDeleteCoupon = true
        })
        .catch((err) => {
          this.$toast({
            title: 'Failed',
            description: err.data.message ?? 'Ops! Something when wrong.',
            status: 'error',
            duration: 5000,
            position: 'bottom-right',
            variant: 'subtle',
          })
        }).finally(() => {
          this.isLoadingDeleteCoupon = false
          this.isOpenModalConfirmDeleteCoupon = false
        })
    },
  },
}
</script>
